const getRandom10char = () => {
  return (
    Math.random().toString(36).substr(2, 10) +
    Math.random().toString(36).substr(2, 10) +
    Math.random().toString(36).substr(2, 10)
  );
};
const getRandomAmount = () => {
  return Math.floor(Math.random() * 1000);
};
const RandomData = [
  {
    name: "Bitcoin",
    network: "Mainnet",
    type: "in",
    image: "/coins/bitcoin.png",
    address: "0x" + getRandom10char(),
    amount: getRandomAmount(),
    date: new Date().toLocaleDateString(),
  },
  {
    name: "Ethereum",
    network: "Mainnet",

    type: "in",
    image: "/coins/eth.png",
    address: "0x" + getRandom10char(),
    amount: getRandomAmount(),
    date: new Date().toLocaleDateString(),
  },
  {
    name: "Bitcoin",
    network: "Mainnet",
    image: "/coins/bitcoin.png",
    address: "0x" + getRandom10char(),
    amount: getRandomAmount(),

    type: "out",

    date: new Date().toLocaleDateString(),
  },
  {
    name: "Ethereum",
    network: "Mainnet",
    image: "/coins/eth.png",

    type: "in",
    address: "0x" + getRandom10char(),
    amount: getRandomAmount(),
    date: new Date().toLocaleDateString(),
  },
  {
    name: "Solana",
    network: "Mainnet",
    type: "out",
    image: "/coins/solana.png",
    address: "0x" + getRandom10char(),
    amount: getRandomAmount(),
    date: new Date().toLocaleDateString(),
  },
  {
    name: "Doge",

    type: "out",
    network: "Mainnet",
    image: "/coins/doge.png",
    address: "0x" + getRandom10char(),
    amount: getRandomAmount(),
    date: new Date().toLocaleDateString(),
  },
  {
    name: "Bnb",
    network: "Mainnet",
    type: "out",
    image: "/coins/bnb.png",
    address: "0x" + getRandom10char(),
    amount: getRandomAmount(),
    date: new Date().toLocaleDateString(),
  },
  {
    name: "litecoin",
    network: "Mainnet",
    type: "in",
    image: "/coins/litcoin.png",
    address: "0x" + getRandom10char(),
    amount: getRandomAmount(),
    date: new Date().toLocaleDateString(),
  },
  {
    name: "tron",
    network: "Mainnet",
    image: "/coins/tron.png",
    type: "in",
    address: "0x" + getRandom10char(),
    amount: getRandomAmount(),
    date: new Date().toLocaleDateString(),
  },
];
const CoinsImages = [
  {
    name: "Bitcoin",
    image: "/coins/bitcoin.png",
    symbol: "BTC",
    amount: 1,
    kego: "bitcoin",
    price: 22000,
  },
  {
    name: "Ethereum",
    image: "/coins/eth.png",
    symbol: "ETH",
    kego: "ethereum",
    amount: 3,
    price: 1400,
  },
  {
    name: "Solana",
    image: "/coins/solana.png",
    symbol: "SOL",
    amount: 10,
    kego: "solana",
    price: 22,
  },
  {
    name: "Doge",
    image: "/coins/doge.png",
    symbol: "DOGE",
    amount: 10000,
    kego: "dogecoin",
    price: 0.014,
  },
  {
    name: "Bnb",
    image: "/coins/bnb.png",
    symbol: "BNB",
    amount: 10,
    kego: "binancecoin",
    price: 300,
  },
  {
    name: "litecoin",
    image: "/coins/litcoin.png",
    symbol: "LTC",
    amount: 10,
    kego: "litecoin",
    price: 200,
  },
  {
    name: "tron",
    image: "/coins/tron.png",
    symbol: "TRX",
    amount: 1000,
    kego: "tron",
    price: 0.02,
  },
];

const SubCoinss = [
  [
    {
      name: "Bitcoin",
      image: "/coins/bitcoin.png",
      symbol: "BTC",
      amount: 40,
    },
    {
      name: "Ethereum",
      image: "/coins/eth.png",
      symbol: "ETH",
      amount: 19,
    },
    {
      name: "Solana",
      image: "/coins/solana.png",
      symbol: "SOL",
      amount: 10,
    },
  ],
  [
    {
      name: "Doge",
      image: "/coins/doge.png",
      symbol: "DOGE",
      amount: 10,
    },
    {
      name: "Bnb",
      image: "/coins/bnb.png",
      symbol: "BNB",
      amount: 30,
    },
    {
      name: "litecoin",
      image: "/coins/litcoin.png",
      symbol: "LTC",
      amount: 10,
    },
  ],
  [
    {
      name: "tron",
      image: "/coins/tron.png",
      symbol: "TRX",
      amount: 10,
    },
  ],
];

const newsItems = [
  {
    id: 1,
    coin: "Bitcoin",
    title: "Bitcoin hits new all-time high",
    image:
      "https://images.unsplash.com/photo-1610651568348-c00e63e64f27?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTJ8fGJpdGNvaW58ZW58MHx8MHx8&ixlib=rb-1.2.1&w=1000&q=80",
    description:
      "Bitcoin surged to a new all-time high of $60,000 today, continuing its remarkable run in 2021.",
  },
  {
    id: 2,
    coin: "Ethereum",
    title: "Ethereum 2.0 upgrade goes live",
    image:
      "https://images.unsplash.com/photo-1610651568348-c00e63e64f27?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTJ8fGJpdGNvaW58ZW58MHx8MHx8&ixlib=rb-1.2.1&w=1000&q=80",
    description:
      "The highly anticipated Ethereum 2.0 upgrade has finally gone live, bringing a host of improvements to the popular blockchain.",
  },
  {
    id: 3,
    coin: "Dogecoin",
    title: "Elon Musk tweets about Dogecoin, causing price surge",
    image:
      "https://images.unsplash.com/photo-1610651568348-c00e63e64f27?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTJ8fGJpdGNvaW58ZW58MHx8MHx8&ixlib=rb-1.2.1&w=1000&q=80",
    description:
      "Tesla CEO Elon Musk tweeted about Dogecoin today, causing the price of the cryptocurrency to surge by over 20% in a matter of hours.",
  },
];

const toPlainString = (num: any): any => {
  //bnbPrice
  return ("" + +num).replace(
    /(-?)(\d*)\.?(\d*)e([+-]\d+)/,
    function (a, b, c, d, e) {
      return e < 0
        ? b + "0." + Array(1 - e - c.length).join(0 as any) + c + d
        : b + c + d + Array(e - d.length + 1).join(0 as any);
    }
  );
};
const FormateString = (tring: any): any => {
  let Items =
    tring != null || tring != undefined
      ? String(tring).includes(".")
        ? tring
        : tring.toLocaleString()
      : "-";
  return Items;
};

export {
  RandomData,
  toPlainString,
  FormateString,
  CoinsImages,
  newsItems,
  SubCoinss,
};
