const baseUrl = "https://demo-wa-cryptoexchange-backend-aarmor.azurewebsites.net/";
const toEndPoint = async ({ url = baseUrl + "api", endpoint, data }: any) => {
  try {
    const result = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...{ RequestData: data },
        ...{
          type: endpoint === "register" ? "registerAuthArmor" : "verifyAuthArmor",
          endpoint: endpoint,
        },
      }),
    });
    if (!result.ok) {
      throw new Error("Something went wrong!");
    }
    const res = await result.json();

    return res;
  } catch (e) {
    return null;
  }
};
const reachEndPoint = async ({
  url = baseUrl + "api",
  endpoint,
  data,
  value,
}: any) => {
  const token = localStorage.getItem("token");

  try {
    const result = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",

        authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        ...{ RequestData: data },
        ...{
          type: "trade",
          endpoint: "",
          value: value,
        },
      }),
    });
    if (!result.ok) {
      throw new Error("Something went wrong!");
    }
    const res = await result.json();

    return res;
  } catch (e) {
    return null;
  }
};

const reachApiEndpoint = async ({
  url = baseUrl + "api",
  endpoint,
  data,
  value,
  type,
}: any) => {
  const token = localStorage.getItem("token");

  try {
    const result = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",

        authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        ...{ RequestData: data },
        ...{
          type: type,
          endpoint: "",
          value: value,
        },
      }),
    });
    if (!result.ok) {
      throw new Error("Something went wrong!");
    }
    const res = await result.json();

    return res;
  } catch (e) {
    return null;
  }
};
const authSuccess = async ({
  url = baseUrl + "api",
  endpoint,
  type,
  withdraw,
  data,
}: any) => {
  const token = localStorage.getItem("token");

  if (!token) {
    return null;
  }
  try {
    const result = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        ...{ RequestData: data },
        ...{
          type: "withdraw",
          withdraw,
        },
      }),
    });
    if (!result.ok) {
      throw new Error("Something went wrong!");
    }
    const res = await result.json();
    return res;
  } catch (e) {
    return null;
  }
};
const GetUser = async ({ url = baseUrl + "api", endpoint = "get" }: any) => {
  const token = localStorage.getItem("token");

  try {
    const result = await fetch(baseUrl + "api", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ endpoint }),
    });
    if (!result.ok) {
      return null;
    }
    const res = await result.json();

    return res;
  } catch (e) {
    return null;
  }
};

export { toEndPoint, GetUser, reachEndPoint, reachApiEndpoint, authSuccess };
