import React, { CSSProperties, useMemo } from "react";
import {
  AuthArmorClient,
  AuthenticationFailureReason,
  IAuthenticationFailureResult,
  IAuthenticationSuccessResult,
  IRegistrationSuccessResult,
} from "@autharmor/autharmor-js";
import "@autharmor/autharmor-js-ui/webc";
import "@autharmor/autharmor-js-ui/autharmor.css";
import { AuthArmorForm } from "@autharmor/autharmor-react";
import { toEndPoint, authSuccess } from "../api";
import {
  IAuthArmorInteractiveClientConfiguration,
  IUiOptions,
  LogInEvent,
  RegisterEvent,
} from "@autharmor/autharmor-js-ui";

const clientSdkApiKey =
  "eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IjhiNzhkYTFmLWU4NWEtNDg4Ny05MmQxLTcyNWZlNTg3MGFiZCJ9.eyJrZXkiOiJRSENUSHo3TU5zVVViNzNldGFoTU4ydmtzamZXWEF2VnhrdHp3VnVxWXd1TksyWHIiLCJpYXQiOjE2NzgxMTYyMjd9.2IC9CqlksIA-jXXcDtPDJ77-OoEaESOuDmnIp_H8F3CNYJhXZG5vKSyp2MhGQ4CwGPbUjomu8bASxTbk0IYUBg";
const webAuthnClientId = "ac16a9cb-28b9-47ed-9195-9a2feac5653f";

const client = new AuthArmorClient({
  clientSdkApiKey,
  webAuthnClientId,
});

const defaultStyles: CSSProperties = {
  display: "block",
  backgroundColor: "hsl(40deg 100% 75%)",
  borderRadius: "8px",
  padding: "24px 32px 16px",
  width: "min(30rem, 95vw)",
  maxHeight: "95vh",
  overflowY: "auto"
};

const AskForValue = ({
  removeit,
  coin,
  address,
  Amount,
  setLoading,
  dismisss,
}: any) => {
  const actionName = `Confirm ${coin.symbol} withdraw`;
  const shortMessage = `Please confirm a pending withdraw of ${Amount} ${coin.symbol} to ${address}`;

  const interactiveConfig = useMemo<IAuthArmorInteractiveClientConfiguration>(
    () => ({
      defaultAuthenticateOptions: {
        timeoutSeconds: 60,
      },
      defaultAuthenticatorAuthenticateOptions: {
        actionName,
        shortMessage,
      },
      defaultMagicLinkEmailAuthenticateOptions: {
        actionName,
        shortMessage,
      },
    }),
    [actionName, shortMessage]
  );

  const onLogInSuccess = async (data: IAuthenticationSuccessResult) => {
    setLoading();
    let result;
    try {
      result = await authSuccess({
        type: "withdraw",
        address: address ? address : "432432",
        withdraw: coin,
        data,
      });
    } catch (e) {}

    dismisss({
      success: result ? true : false,
      message: result ? "Withdraw successfull" : "Withdraw Failed",
      code: "success",
      result: result,
      tx: result
        ? "0x" +
          Math.random().toString(36).substring(2, 15) +
          Math.random().toString(36).substring(2, 15) +
          Math.random().toString(36).substring(2, 15)
        : "",
    });

    removeit();
  };

  const onLogInFailure = async (data: IAuthenticationFailureResult) => {
    const message = (
      {
        timedOut: "Time out",
        declined: "Auth Declined",
        unknown: "Withdraw Failed",
      } as Record<AuthenticationFailureReason, string>
    )[data.failureReason];

    const code = data.failureReason === "timedOut" ? "authTimeout" : "rejected";

    dismisss({
      success: false,
      message,
      code,
      tx: "",
      result: null,
    });

    removeit();
  };

  return (
    <AuthArmorForm
      client={client}
      interactiveConfig={interactiveConfig}
      style={defaultStyles}
      action="logIn"
      username={window.ReactUser.userName}
      onLogIn={onLogInSuccess}
      onLogInFailure={onLogInFailure}
    />
  );
};

const Form = ({
  dismisss,
  startLoading,
  defaultTab,
}: {
  dismisss: (val: any) => void;
  startLoading: () => void;
  defaultTab: "login" | "register";
}) => {
  const onLogInSuccess = async (data: LogInEvent) => {
    startLoading();
    const result = await toEndPoint({ endpoint: "login", data });

    dismisss({
      success: result ? true : false,
      message: result ? "Log in successfull" : "user not found",
      result: result,
      place: "login",
    });
  };

  const onRegisterSuccess = async (data: RegisterEvent) => {
    startLoading();
    const result = await toEndPoint({ endpoint: "register", data });

    // window.location?.reload();
    dismisss({
      success: result ? true : false,
      message: result ? "Create account successfull" : "error creating user",
      result: { ...result, userName: data.registrationResult.username },
      place: "register",
    });
  };

  return (
    <AuthArmorForm
      client={client}
      defaultAction={defaultTab === "register" ? "register" : "logIn"}
      onLogIn={onLogInSuccess}
      onRegister={onRegisterSuccess}
      style={defaultStyles}
    />
  );
};
export { Form, AskForValue };
