import { buildStyles, CircularProgressbar } from "react-circular-progressbar";

const Charts = ({ name, amount }: any) => {
  const percentage = 50;
  return (
    <div className="ChartsRow">
      <div style={{ height: 30, width: 30 }}>
        <CircularProgressbar
          value={amount}
          text={""}
          styles={buildStyles({
            textSize: "19px",

            pathTransitionDuration: 0.5,

            pathColor: "#bb7d06",
            textColor: "white",
            trailColor: "white",
          })}
        />
      </div>
      <div className="ChartsName">
        <span style={{ color: "#bb7d06", fontWeight: 600 }}>{amount}%</span>{" "}
        {name}
      </div>
    </div>
  );
};
export { Charts };
