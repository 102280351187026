import React from "react";
import "./App.css";
import { createContext, useContext, useState } from "react";
import { lazy } from "react";
import Lottie from "lottie-react";
import "react-dropdown/style.css";
import { CoinsImages } from "./extradata";
import "swiper/css";
import "swiper/css/bundle";
import "react-circular-progressbar/dist/styles.css";
import DataLoader from "./pages/LoadingData";
import { IoClose, IoCloseCircle } from "react-icons/io5";
import { GetUser } from "./api";
import { MdCheckCircle, MdError } from "react-icons/md";
import Pricelistcontainer from "./components/pricelistcontainer";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import HeaderExport from "./components/header/header";
import { SideNav } from "./components/sidenav";
import { Avaiable } from "./components/amountswiper";
import { NewBlog } from "./components/newPagnation";
import { Deps } from "./components/transaction";
import { GetToken, MyTokens } from "./components/alltoken";
import { ItemHeader } from "./components/dashboardHeader";
import { OverView } from "./components/OverView";
import { TradeView } from "./components/tradeView";
import { Withdraw } from "./withdraw";
import { OverViewItem } from "./components/ItemNeeded";
import { Accounts } from "./components/TradeCoin";

import * as animationData from "./crypto-wallet.json";
import * as animationDatas from "./loadingjson.json";
import { Form } from "./components/form";
import ReactLoading from "react-loading";
const Dashboard = lazy(() => import("./dashboard"));
declare global {
  interface Window {
    ReactUser: any;
    ReactCoins: any;
    ReactWalkThrough: any;
  }
}
const Titles = ["Dashboard", "Statiscs", "Wallet", "Trade", "Account"];
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const defaultOptionss = {
  loop: true,
  autoplay: true,
  animationData: animationDatas,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const AppWraper = createContext({});

function App() {
  const [isVisible, setIsVisible] = React.useState(true);
  const [WhichTab, SetTab] = React.useState(0);
  const [Prices, SetPRice] = React.useState<any>([]);
  const [isVisibles, setVisible] = React.useState<any>(null);
  const [result, setResult] = React.useState<any>(null);
  const [loadingForm, SetLoadingForm] = React.useState(false);
  const [showPopup, setShowIT] = React.useState<any>(null);

  const [activeOnBoarding, SetActiveOnBoarding] = React.useState<any>({});
  const [User, SetUser] = React.useState<any>({});
  const [OffialLoadng, SetOfficalLoading] = React.useState(true);
  const [iTemVisible, SetItemVisible] = React.useState<any>(null);

  const InCaseNull = [
    {
      symbol: "ETHUSDT",
      price: "1645.23000000",
    },
    { symbol: "BTCUSDT", price: "24009.21000000" },
    { symbol: "TRXUSDT", price: "0.06962000" },
    { symbol: "DOGEUSDT", price: "0.08492000" },
    { symbol: "LTCUSDT", price: "95.19000000" },
    { symbol: "BNBUSDT", price: "309.90000000" },
    { symbol: "SOLUSDT", price: "24.16000000" },
  ];
  React.useEffect(() => {
    if (
      window.ReactWalkThrough &&
      window.ReactWalkThrough.header &&
      window.ReactWalkThrough.withdraw &&
      window.ReactWalkThrough.SideNav
    ) {
      if (iTemVisible === null) {
        SetItemVisible({
          header: {
            height: window.ReactWalkThrough.header.clientHeight,
            width: window.ReactWalkThrough.header.clientWidth,
            top: window.ReactWalkThrough.header.offsetTop,
            left: window.ReactWalkThrough.header.offsetLeft,
          },
          withdraw: {
            height: window.ReactWalkThrough.withdraw.clientHeight,
            width: window.ReactWalkThrough.withdraw.clientWidth,
            top: window.ReactWalkThrough.withdraw.offsetTop,
            left: window.ReactWalkThrough.withdraw.offsetLeft,
          },
          SideNav: {
            height: window.ReactWalkThrough.SideNav.clientHeight,
            width: window.ReactWalkThrough.SideNav.clientWidth,
            top: window.ReactWalkThrough.SideNav.offsetTop,
            left: window.ReactWalkThrough.SideNav.offsetLeft,
          },
        });
      }

      //offsetTop
    }
  });
  React.useEffect(() => {
    async function Add() {
      const Coins = CoinsImages.map(async (data) => {
        const value = await GetToken({ symbol: data.symbol });
        const its = `${data.symbol}USDT`;
        const Valus = InCaseNull.find((item) => item.symbol === its);

        const Itmes = {
          [data.symbol]: value ? value : Valus?.price,
        };
        return Itmes;
      });
      Promise.all(Coins).then(function (results) {
        window.ReactCoins = results;
        SetPRice(results);
      });
    }
    Add();
  }, []);
  React.useEffect(() => {
    if (result) {
      setShowIT(result);

      setTimeout(() => {
        setShowIT(null);
      }, 2000);
    }
  }, [result]);
  React.useEffect(() => {
    try {
      const token = localStorage.getItem("token");
      if (token) {
        const Thuser = async () => {
          const vla = await GetUser({ token: token })
            .then((res: any) => {
              if (!res) {
                throw new Error("err");
              }
              setTimeout(() => {
                if (res.id) {
                  SetOfficalLoading(false);
                  window.ReactUser = res;
                  SetUser(res);
                } else {
                  SetOfficalLoading(false);
                }
              }, 1000);
            })
            .catch((err: any) => {
              setTimeout(() => {
                SetOfficalLoading(false);
              }, 500);
            });
        };

        Thuser();
      } else {
        setTimeout(() => {
          SetOfficalLoading(false);
        }, 1000);
      }
    } catch (err) {
      setTimeout(() => {
        SetOfficalLoading(false);
      }, 1000);
    }
  }, []);
  React.useEffect(() => {
    if (showPopup) {
      setTimeout(() => {
        if (
          result.success &&
          (result.place === "login" || result.place === "register")
        ) {
          window.ReactUser = result.result;
          localStorage.setItem("token", result.result.token);
          SetUser(result.result);
        }
      }, 500);
    }
  }, [showPopup]);
  React.useEffect(() => {
    if (localStorage.getItem("walkthroughDone") === "true") {
      window.ReactWalkThrough = {
        ...window.ReactWalkThrough,
        active: 30
      };
    }
  }, []);

  if (OffialLoadng) {
    return (
      <div
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Lottie
          {...defaultOptionss}
          style={{ marginTop: 50 }}
          height={200}
          width={250}
        />
      </div>
    );
  }

  if (User && User.userName !== undefined) {
    return (
      <div className="Apps">
        {showPopup ? (
          <div className="PopUP_Holder">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 7,
              }}
            >
              <div
                style={{
                  paddingTop: 3,
                }}
              >
                {showPopup.success ? (
                  <MdCheckCircle color={"#8dff8d"} size={25} />
                ) : (
                  <MdError color={"#ff5454"} size={25} />
                )}
              </div>
              <div className="PopUpFont">{showPopup.message}</div>
            </div>
            <IoCloseCircle
              onClick={() => {
                setShowIT(null);
              }}
              color={"white"}
              size={25}
            />
          </div>
        ) : null}

        <main style={{ display: "flex" }}>
          {(iTemVisible && !window.ReactWalkThrough.active) ||
          (window.ReactWalkThrough &&
            window.ReactWalkThrough.active &&
            window.ReactWalkThrough.active < 6) ? (
            <div
              onClick={() => {
                window.ReactWalkThrough.active = window.ReactWalkThrough.active
                  ? window.ReactWalkThrough.active + 1 > 5
                    ? 30
                    : window.ReactWalkThrough.active + 1
                  : 1;

                SetActiveOnBoarding({
                  active: window.ReactWalkThrough.active,
                  item:
                    window.ReactWalkThrough.active !== 5
                      ? iTemVisible.withdraw
                      : iTemVisible.header,
                });
                if (getWindowDimensions().width < 600) {
                  if (window.ReactWalkThrough.active === 1) {
                    window.scrollBy(
                      0,
                      window.ReactWalkThrough.withdraw.offsetTop / 2
                    );
                  } else {
                    if (window.ReactWalkThrough.active === 5) {
                      window.scrollTo(0, 0);
                    }
                  }
                }
                localStorage.setItem(
                  "active",
                  window.ReactWalkThrough.active > 6
                    ? ""
                    : JSON.stringify(iTemVisible)
                );

                if (window.ReactWalkThrough.active > 6)
                  localStorage.setItem("walkthroughDone", "true");

                window.dispatchEvent(new Event("storage"));
              }}
              style={{
                position: "fixed",
                backgroundColor: "rgba(0,0,0,0.6)",
                width: "100vw",

                zIndex: 20,
                height: "100vh",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <div
                  style={{
                    ...{
                      position: "absolute",
                      top:
                        activeOnBoarding.item?.top +
                        (getWindowDimensions().width < 960 &&
                        activeOnBoarding.active < 5
                          ? 70
                          : activeOnBoarding.item?.height +
                            (activeOnBoarding.active < 5 ? 80 : 0)),

                      left: activeOnBoarding.item?.left,
                      width: "fit-content",
                      minWidth: 80,
                      padding: 20,
                      margin: 20,
                      zIndex: 99990000000000,
                      fontFamily: "Poppins",
                      fontSize: "0.8em",
                      borderRadius: 10,
                      borderTopRightRadius: 0,
                      backgroundColor: "white",
                    },
                    ...(activeOnBoarding.active < 1 &&
                    activeOnBoarding.active > 4
                      ? {
                          position: "relative",
                        }
                      : {}),
                  }}
                >
                  {![1, 2, 3, 4, 5].includes(activeOnBoarding.active)
                    ? "Welcome to Crypto Armor, a demo website showcasing Auth Armor authentication"
                    : null}
                  {activeOnBoarding.active == 1
                    ? "Make a withdraw to use Auth Armor 2FA"
                    : null}
                  {activeOnBoarding.active == 2
                    ? "Enter the amount you wish to withdraw"
                    : null}
                  {activeOnBoarding.active == 3
                    ? "Enter any value here, this is just a demo"
                    : null}
                  {activeOnBoarding.active == 4
                    ? "Click withdraw to start Auth Armor 2FA"
                    : null}
                  {activeOnBoarding.active == 5
                    ? "Disable and enabel 2FA with a click of a button"
                    : null}
                </div>
              </div>
            </div>
          ) : null}

          <SideNav
            returnIndex={(value: number) => {
              SetTab(value);
            }}
          />
          <Dashboard
            Prices={Prices}
            setShowIT={(result: any) => setShowIT(result)}
            setAccount={() => {
              SetTab(4);
            }}
            WhichTab={WhichTab}
          />
        </main>
      </div>
    );
  }
  return (
    <div>
      {showPopup ? (
        <div className="PopUP_Holder">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 7,
            }}
          >
            <div
              style={{
                paddingTop: 3,
              }}
            >
              {showPopup.success ? (
                <MdCheckCircle color={"#8dff8d"} size={25} />
              ) : (
                <MdError color={"#ff5454"} size={25} />
              )}
            </div>
            <div className="PopUpFont">{showPopup.message}</div>
          </div>
          <IoCloseCircle
            onClick={() => {
              setShowIT(null);
            }}
            color={"white"}
            size={25}
          />
        </div>
      ) : null}
      <HeaderExport setIsVisible={() => {}} />

      {isVisibles ? (
        <div className="ShowingwithdrawContainers_form" onClick={e => e.target === e.currentTarget && setVisible(null)}>
          <div onClick={() => setVisible(null)} className="CloseContainer_form">
            <IoClose size={25} color={"white"} />
          </div>
          {loadingForm ? (
            <ReactLoading
              type={"balls"}
              color={"white"}
              height={25}
              width={25}
            />
          ) : null}
          <div className="withdrawContainersAbsolute_form">
            <Form
              defaultTab={isVisibles}
              startLoading={() => {
                SetLoadingForm(true);
              }}
              dismisss={(val) => {
                setResult(val);
                SetLoadingForm(false);
                setVisible(null);
              }}
            />
          </div>
        </div>
      ) : null}
      <div className="WebsiteHeader">
        <div className="HeaderContainer">
          <h2 className="headerText">
            A new creative way to trade crypto with high security
            <br />
            and low fees
          </h2>

          <div className="Names">
            <div
              className="SignIn"
              onClick={() => {
                setVisible("login");
              }}
              style={{
                cursor: "pointer",
              }}
            >
              sign in
            </div>
            <div className="HorSpe" />
            <div
              className="SignIn"
              onClick={() => {
                setVisible("register");
              }}
              style={{
                background: "white",
                color: "#1c202b",
                cursor: "pointer",
              }}
            >
              sign up
            </div>
          </div>
        </div>
        <Lottie
          {...defaultOptions}
          style={{ marginTop: 50 }}
          height={"50%"}
          width={"80%"}
        />
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          overflowX: "scroll",
        }}
        className="Pricelist"
      >
        <Pricelistcontainer />
      </div>
      <div
        style={{
          width: "88%",
          top: 3,
          marginTop: 14,
          marginLeft: 14,
          fontSize: 12,
          fontWeight: 400,
          fontFamily: "Poppins",
          color: "darkgray",
        }}
      >
        This is only a sample exchange website - this website was built to
        showcase Auth Armor
        <a
          href="https://autharmor.com/"
          style={{ color: "white", letterSpacing: 1 }}
        >
          Auth Armor
        </a>
        authenticator capabilities
      </div>
    </div>
  );
}
export default App;
