interface HeaderProps {
  setIsVisible: (isVisible: boolean) => void;
}
const HeaderExport = ({ setIsVisible }: HeaderProps) => {
  return (
    <div className="App" style={{}}>
      <div
        style={{
          width: "100vw",
          height: 90,
          justifyContent: "space-between",
          alignItems: "center",
          padding: 20,
          display: "flex",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontFamily: "Poppins",
            letterSpacing: 1,
          }}
        >
          <img
            src="/logo.png"
            style={{ width: 50, height: 50, objectFit: "contain" }}
            alt=""
          />
          <div style={{ color: "white", fontWeight: 500 }}>
            Crpyto
            <span style={{ color: "#BB7D06", fontWeight: 700 }}>Armor</span>
          </div>

          <div className="sperator" />
          <div className="Menu">
            <div>Explore</div>
            <div>Coins</div>
            <div>Statistics</div>
            <div>Blog</div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default HeaderExport;

/**
 * <div
          onClick={() => {
            setIsVisible(true);
          }}
          style={{
            border: "1px solid #BB7D06",
            padding: 5,
            width: "fit-content",
            borderRadius: 5,
            fontSize: "0.8rem",
            fontFamily: "Poppins",
            fontWeight: 300,
            color: "white",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Get started
        </div>
 */
