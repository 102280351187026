import {
  MdAccountBalanceWallet,
  MdAddchart,
  MdDashboard,
  MdOutlineIosShare,
  MdOutlinePoll,
} from "react-icons/md";

import { IoSwapVerticalSharp } from "react-icons/io5";
import React from "react";
import { act } from "react-dom/test-utils";

const SideNav = ({ returnIndex }: any) => {
  //Dashboard
  //Markets
  //Assets
  //Transcations

  //Trade
  const iconSize = 18;
  const [active, setActive] = React.useState(0);
  function setActiveTable(index: number) {
    setActive(index);
    returnIndex(index);
  }
  React.useEffect(() => {}, [active]);
  const refItem = React.useRef(null);
  React.useEffect(() => {
    window.ReactWalkThrough = {
      ...(window.ReactWalkThrough || {}),
      ...{ SideNav: refItem.current },
    };
  }, []);
  const [activeItem, setActiveItem] = React.useState<any>({});
  React.useEffect(() => {
    const handleStorage = (e: any) => {
      if (window.ReactWalkThrough && window.ReactWalkThrough.active) {
        if (window.ReactWalkThrough.active > 5) {
          setActiveItem({ id: 90 });
        } else {
          setActiveItem({});
        }
      }
    };
    window.addEventListener("storage", handleStorage);
    return () => {
      window.removeEventListener("storage", handleStorage);
    };
  }, []);
  return (
    <div
      className="SideNav"
      style={{
        zIndex: 21,
      }}
    >
      {!activeItem.id ||
      (activeItem.id &&
        activeItem.id < 7 &&
        window.ReactWalkThrough &&
        window.ReactWalkThrough.active &&
        window.ReactWalkThrough.active < 7) ? (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 21,
            background: "rgba(0,0,0,0.5)",
          }}
        ></div>
      ) : null}
      <div className="LogoContainer">
        <img
          src="/logo.png"
          style={{ width: 45, height: 45, objectFit: "contain" }}
          alt=""
        />
      </div>
      <div
        onClick={() => setActiveTable(0)}
        className={`NavElement ${active === 0 ? "selected" : ""}`}
        ref={refItem}
        style={{
          ...(activeItem.id && activeItem.id === 3 ? activeItem.style : {}),
        }}
      >
        <div>
          <MdDashboard size={iconSize} color={"lightgray"} />
        </div>
        <p>Dashboard</p>
      </div>
      <div
        onClick={() => setActiveTable(1)}
        className={`NavElement  ${active === 1 ? "selected" : ""}`}
        style={{
          ...(activeItem.id && activeItem.id === 4 ? activeItem.style : {}),
        }}
      >
        <div>
          <MdOutlinePoll size={iconSize} color={"lightgray"} />
        </div>
        <p>Statiscs</p>
      </div>
      <div
        onClick={() => setActiveTable(2)}
        className={`NavElement  ${active === 2 ? "selected" : ""}`}
        style={{
          ...(activeItem.id && activeItem.id === 5 ? activeItem.style : {}),
        }}
      >
        <div>
          <MdAccountBalanceWallet size={iconSize} color={"lightgray"} />
        </div>
        <p>Assets</p>
      </div>

      <div
        onClick={() => setActiveTable(3)}
        className={`NavElement  ${active === 3 ? "selected" : ""}`}
        style={{
          ...(activeItem.id && activeItem.id === 6 ? activeItem.style : {}),
        }}
      >
        <div className="subItem">
          <MdAddchart size={iconSize} color={"lightgray"} />
        </div>
        <p>Trade</p>
      </div>
    </div>
  );
};
export { SideNav };
