import React from "react";
import { CoinsImages } from "../extradata";

import { FormateString } from "../extradata";
import { Charts } from "./donutChart";
const GetToken = async ({
  symbol,
}: {
  symbol: string;
}): Promise<null | string> => {
  try {
    let Datas = await fetch(
      `https://api.binance.us/api/v3/ticker/price?symbol=${symbol.toUpperCase()}USDT`,
      {
        method: "GET",
      }
    );
    let result = await Datas.json();
    if (result && result.price) {
      return result.price;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
};

const Tokens = ({
  selectedAble,
  selected,
  vertical,
  amount,
  name,
  padding,
  clickAble,
  selectedToken,
  background,
  symbol,
  SmallWidht,
  image,
}: any) => {
  const extra = padding ? { padding: 5 } : {};
  let ItemPric = 0;
  let prices = 0;
  let totalValue = 0;
  window.ReactUser.coins.map((data: any) => {
    try {
      totalValue +=
        window.ReactCoins.find((item: any, index: string) => {
          return Object.keys(window.ReactCoins[index])[0] === data.symbol;
        })[data.symbol] * data.amount;
    } catch (e) {}
  });
  try {
    ItemPric = window.ReactCoins.find((item: any, index: string) => {
      return Object.keys(window.ReactCoins[index])[0] === symbol;
    })[symbol];
  } catch (e) {}
  try {
    prices =
      window.ReactCoins.find((item: any, index: string) => {
        return Object.keys(window.ReactCoins[index])[0] === symbol;
      })[symbol] * amount;
  } catch (e) {}
  const vals: number = prices / totalValue;
  const price: any = Number(ItemPric).toFixed(3);
  //  window.ReactCoins.find((item: any) => item.symbol === symbol).symbol || 0;

  let nf = new Intl.NumberFormat("en-US");

  return (
    <div
      onClick={() => {
        if (selectedAble || clickAble) {
          selected(symbol);
        }
      }}
      key={symbol}
      className="TokenBubble"
      style={{
        ...{},
        ...extra,
        background:
          background && selectedToken !== symbol
            ? background
            : selectedToken === symbol
            ? "rgba(0,0,0,0.4)"
            : "rgba(255,255,255,0.1)",
        borderBottom: background ? "0.5px solid darkgray" : "none",
        ...(SmallWidht ? { width: 140, minWidth: 90 } : {}),
      }}
    >
      <div className="TopContainer">
        <div
          className="symbolCoin"
          style={{
            background: selectedAble ? "transparent" : "rgb(187, 125, 6)",
            color: selectedAble ? "white" : "black",
          }}
        >
          {name}
        </div>
        <div className="TokenBubbleImage">
          {selectedAble ? null : (
            <div className="LivePrice">
              ${nf.format(Number(price).toFixed(3) as any)}
            </div>
          )}
          <img src={image} alt="" />
        </div>
      </div>
      {selectedAble ? null : (
        <div className="AmountCounter">
          ${nf.format(Number(amount).toFixed(3) as any)} {symbol}
          <span className="TokenBu">
            ≈ ${nf.format(Number(price * amount).toFixed(3) as any)}
          </span>
        </div>
      )}
      {selectedAble ? null : (
        <div>
          {vertical ? <div style={{ width: 9, height: 8 }} /> : null}
          <Charts amount={(Number(vals) * 100).toFixed(1)} name={symbol} />
          {vertical ? <div style={{ width: 9, height: 8 }} /> : null}
        </div>
      )}
    </div>
  );
};

const MyTokens = ({
  padding,
  background,
  selected,
  SmallWidht,
  clickAble,
  vertical,
  selectedAble,
}: any) => {
  const [selectedToken, setSelectedToken] = React.useState<any>(null);
  const sselected = (value: any) => {
    setSelectedToken(value);
  };
  React.useEffect(() => {
    if (selected !== undefined) {
      selected(selectedToken);
    }
  }, [selectedToken]);
  return (
    <div className="overView" style={{ width: "100%" }}>
      <div className="overViewTitle">Assets</div>
      <div
        className="TokensContainers"
        style={{
          flexDirection: vertical ? "column" : "row",
        }}
      >
        {window.ReactUser.coins.map((data: any, index: any) => {
          let item: any = CoinsImages.find(
            (item: any) => item.symbol === data.symbol
          );
          return (
            <Tokens
              amount={data.amount}
              symbol={item.symbol}
              key={index}
              SmallWidht={SmallWidht}
              selected={sselected}
              name={item.name}
              background={background}
              image={item.image}
              vertical={vertical}
              clickAble={clickAble}
              selectedToken={selectedToken}
              padding={padding}
              selectedAble={selectedAble}
            />
          );
        })}
      </div>
    </div>
  );
};

export { MyTokens, GetToken };
