import React, { Component, useEffect, useState } from "react";
import Prices from "./pricelist";
export const SomeDataArray = [
  {
    SYMBOL: "SHIB",
    Name: "SHIBA INU",
    coinData: "shiba-inu",
    Ticker: "shibusdt",
    WITHDRAW: "true",
    alert: "true",
    Network: "BEP20",
    icon: "https://s2.coinmarketcap.com/static/img/coins/200x200/5994.png",
    price: "Binance",
    ItemPrice: "",
  },
  {
    SYMBOL: "LUNA",
    Name: "Luna",
    Ticker: "lunausdt",
    WITHDRAW: "true",
    coinData: "terra-luna-2",
    alert: "true",
    Network: "BEP20",
    icon: "https://assets.coingecko.com/coins/images/25767/large/01_Luna_color.png?1653556122",
    price: "Binance",
    ItemPrice: "",
  },
  {
    SYMBOL: "LUNC",
    Name: "Luna classic",
    Ticker: "luncbusd",
    coinData: "terra-luna",
    WITHDRAW: "true",
    alert: "true",
    Network: "BEP20",
    icon: "https://assets.coingecko.com/coins/images/8284/small/01_LunaClassic_color.png?1653547790",
    price: "Binance",
    ItemPrice: "",
  },
  {
    SYMBOL: "BNB",
    Name: "BNB",
    coinData: "binancecoin",
    WITHDRAW: "true",
    Network: "BEP20-BEP2",
    Ticker: "bnbusdt",
    icon: "https://s2.coinmarketcap.com/static/img/coins/200x200/1839.png",
    price: "Binance",
    ItemPrice: "",
  },
  {
    SYMBOL: "BTC",
    Name: "Bitcoin",
    coinData: "bitcoin",
    Network: "BEP2",
    Ticker: "btcusdt",
    icon: "https://img.icons8.com/color/48/000000/bitcoin--v1.png",
    price: "Binance",
    ItemPrice: "",
  },
  {
    SYMBOL: "ETH",

    Network: "BEP20",
    Name: "Ethereum",
    coinData: "ethereum",
    Ticker: "ethusdt",
    icon: "https://upload.wikimedia.org/wikipedia/commons/b/b7/ETHEREUM-YOUTUBE-PROFILE-PIC.png",
    price: "Binance",
    ItemPrice: "",
  },
  {
    SYMBOL: "XRP",

    Network: "BEP20",
    Name: "XRP",
    coinData: "ripple",
    Ticker: "xrpusdt",
    icon: "https://assets.coingecko.com/coins/images/44/thumb/xrp-symbol-white-128.png?1605778731",
    price: "Binance",
    ItemPrice: "",
  },
  {
    SYMBOL: "DOGE",
    coinData: "dogecoin",
    Network: "DOGE",
    Name: "Doge coin",
    Ticker: "dogeusdt",
    icon: "https://upload.wikimedia.org/wikipedia/en/d/d0/Dogecoin_Logo.png",
    price: "Binance",
    WITHDRAW: "true",
    ItemPrice: "",
  },
  {
    SYMBOL: "ADA",

    Network: "ADA",
    coinData: "cardano",
    Name: "Cardano",
    Ticker: "adausdt",
    icon: "https://cryptologos.cc/logos/cardano-ada-logo.png",
    price: "Binance",
    ItemPrice: "",
  },
  {
    SYMBOL: "SOL",

    Network: "SOLANA",
    Name: "Solana",
    coinData: "solana",
    Ticker: "solusdt",
    icon: "https://s2.coinmarketcap.com/static/img/coins/64x64/5426.png",
    price: "Binance",
    ItemPrice: "",
  },
  {
    SYMBOL: "Gala",

    Name: "Gala",
    coinData: "gala",
    Network: "BEP20",
    Ticker: "galausdt",
    icon: "https://s2.coinmarketcap.com/static/img/coins/200x200/7080.png",
    price: "Binance",
    ItemPrice: "",
  },
  {
    SYMBOL: "TRX",
    Name: "Tron",
    coinData: "tron",
    Network: "TRON20",
    Ticker: "trxusdt",
    icon: "https://s2.coinmarketcap.com/static/img/coins/200x200/1958.png",
    price: "Binance",
    WITHDRAW: "true",
    ItemPrice: "",
  },
];

export const TextLangugage = {
  LanguageArray: [
    { Name: "English", Link: "en-US" },
    { Name: "French", Link: "fr" },
    { Name: "Arabic", Link: "ar" },
    { Name: "russian", Link: "ru" },
    { Name: "brazil", Link: "pb" },
    { Name: "polish", Link: "pl" },
    { Name: "portagues", Link: "pt" },
    { Name: "japan", Link: "ja" },
    { Name: "spanish", Link: "es" },
    { Name: "czech", Link: "cze" },
    { Name: "german", Link: "de" },
    { Name: "vietnames", Link: "vi" },
  ],
  en: {
    Price_Chart: "Price Chart",
    live_price: "Live Price",
    Candle_Chart: "Candle Chart",
    You_must_be:
      "You must be signed in or have an account to view this page. you can create an account for free ",
    My_Questions: "My questions",
    My_Comments: "My comments",
    SignOut: "Sign out",
    Invite_code: "Invite code",
    Country: "Country",
    NicKName: "Nick name",
    Name: "Name",
    CreatedAt: "Created at",
    question_view: "Question view",
    Comment: "Comment",
    View: "View",
    Invite: "Invite",

    current_price: "Current price",
    day: "1 Day",
    daythree: "3 Days",
    week: "1 Week",
    month: "1 Month",
    year: "1 Year",
    yearall: "All time",
    Mintues: "1 Mintue",
    hours: "1 hour",

    market_cap: "Market Cap",
    total_supply: "Total supply",
    circulating_supply: "Circulating supply",
    max_supply: "Max supply",
    total_volume: "Total volume",
    fully_diluted_valuation: "Fully diluted valuation",
    All_Time_high: "All Time high",
    All_Time_low: "All Time low",
    Social_medi_counts: "Social media counts:",
    Reddit_community: "Reddit community:",
    categories: "Categories",
    official_page: "Official page",
    Blockchain_sites: "Blockchain sites",
    github: "Github",
    forums: "Forums",
    Scores: "Scores",
    developer: "developer",
    community: "community",
    liquidity: "liquidity",

    LoginIn: "Log in",
    CreateAccount: "Sign up",
    Earn: "Earn",
    Crypto: "Crypto",
    Mintues: "Mintues",
    Home: "Home",
    Conversion: "Conversion",
    ASKOUR: "Browser community about crypto questions",
    PostAquestion: "Post a question",
    Inviting:
      "is Using cryptoscope,and has invited you to join,Create an account and join our communtiy",
    statistics: "statistics",
    invite: "invite",
    languages: "languages",
    news: "news",
    communitry: "communtiy",
    Converter: "Converter",
    MarketPlace: "MarketPalce",
    profile: "profile",
    WatchThe100Coins: "top 100 crypto coins ",
    RankingOfCoins: "Ranking of crypto coins",
    WatchTheLivePrive: "Watch the live price of",
    CandleChartCrypto: "Candlechart of ",
    LivePrices: "Live prices of ",
    Earnshiba: "Earn Free ",
    inviteYourFriend: "Invite your friend and earn Crypto",
    priceChanges24: "how much did the price of  change in the past 24 hours",
    Pricieover: "how much did the price of change over time",
    How: "how to earn shiba for free",
  },
  fr: {
    You_must_be:
      "Vous devez être connecté ou avoir un compte pour voir cette page. vous pouvez créer un compte gratuitement ",
    My_Questions: "Mes questions",
    My_Comments: "Mes commentaires",
    SignOut: "Déconnexion",
    Invite_code: "Code d'invitation",
    Country: "Pays",
    NicKName: "Surnom",
    Name: "Nom",
    CreatedAt: "Créé à",
    question_view: "Vue des questions",
    Comment: "Commentaire",
    View: "Voir",
    Invite: "Inviter",

    My_Questions: "Sorularım",
    My_Comments: "Yorumlarım",
    SignOut: "Oturumu Kapat",
    Invite_code: "Davet kodu",
    Country: "Ülke",
    NicKName: "Takma ad",
    Name: "İsim",
    CreatedAt: "Oluşturma tarihi",
    question_view: "Soru görünümü",
    Comment: "Yorum",
    View: "Görüş",
    Invite: "Davet etmek",

    Mintues: "1 minute",
    hours: "1 heure",
    market_cap: "Capitalisation boursière",
    total_supply: "Offre totale",
    circulating_supply: "Alimentation en circulation",
    max_supply: "Approvisionnement max",
    total_volume: "Volume total",
    fully_diluted_valuation: "Valorisation entièrement diluée",
    All_Time_high: "Haut de tous les temps",
    All_Time_low: "Toujours bas",
    Social_medi_counts: "Les réseaux sociaux comptent :",
    Reddit_community: "Communauté Reddit :",
    categories: "Catégories",
    official_page: "Page Officielle",
    Blockchain_sites: "Sites blockchain",
    github: "Github",
    forums: "Forums",
    Scores: "Scores",
    developer: "développeur",
    community: "communauté",
    liquidity: "liquidité",

    day: "1 jour",
    daythree: "3 jours",
    week: "1 semaine",
    month: "1 mois",
    year: "1 an",
    yearall: "Tous les temps",

    current_price: "prix",
    Price_Chart: "Tableau des prix",
    live_price: "Prix ​​en direct",
    Candle_Chart: "Tableau des bougies",
    LoginIn: "Log in",
    CreateAccount: "Sign up",
    Earn: "Earn",
    Crypto: "Crypto",

    Mintues: "Mintues",
    Home: "Home",
    Conversion: "Conversion",
    ASKOUR: "Browser community about crypto questions",
    PostAquestion: "Post a question",
    Inviting:
      "is Using cryptoscope,and has invited you to join,Create an account and join our communtiy",
    statistics: "statistics",
    invite: "invite",
    languages: "languages",
    news: "news",
    communitry: "communtiy",
    Converter: "Converter",
    MarketPlace: "MarketPalce",
    profile: "profile",
    WatchThe100Coins: "top 100 crypto coins ",
    RankingOfCoins: "Ranking of crypto coins",
    WatchTheLivePrive: "Watch the live price of",
    CandleChartCrypto: "Candlechart of ",
    LivePrices: "Live prices of ",
    Earnshiba: "Earn Free ",
    inviteYourFriend: "Invite your friend and earn Crypto",
    priceChanges24: "how much did the price of  change in the past 24 hours",
    Pricieover: "how much did the price of change over time",
  },
  ar: {
    You_must_be:
      "يجب أن تقوم بتسجيل الدخول أو أن يكون لديك حساب لعرض هذه الصفحة. يمكنك إنشاء حساب مجانًا ",
    My_Questions: "أسئلتي",
    My_Comments: "تعليقاتي",
    SignOut: "خروج",
    Invite_code: "رقم الدعوة",
    Country: "دولة",
    NicKName: "كنية",
    Name: "اسم",
    CreatedAt: "أنشئت في",
    question_view: "عرض السؤال",
    Comment: "تعليق",
    View: "رأي",
    Invite: "يدعو",

    Price_Chart: "مخطط السعر",
    live_price: "سعر مباشر",
    Candle_Chart: "مخطط شمعة",

    current_price: "السعر",

    day: "يوم 1",
    daythree: "3 أيام",
    week: "أسبوع 1",
    month: "شهر واحد",
    year: "1 سنة",
    yearall: "كل الوقت",

    market_cap: "رأس المال السوقي",
    total_supply: "إجمالي العرض",
    circulating_supply: "تعميم العرض",
    max_supply: "ماكس العرض",
    total_volume: "الحجم الكلي",
    fully_diluted_valuation: "تقييم مخفف بالكامل",
    All_Time_high: "عالية طوال الوقت",
    All_Time_low: "منخفضة طوال الوقت",
    Social_medi_counts: "وسائل التواصل الاجتماعي تهم:",
    Reddit_community: "مجتمع Reddit:",
    categories: "فئات",
    official_page: "الصفحة الرسمية",
    Blockchain_sites: "مواقع Blockchain",
    github: "جيثب",
    forums: "المنتديات",
    Scores: "درجات",
    developer: "مطور",
    community: "تواصل اجتماعي",
    liquidity: "سيولة",

    Mintues: "1 دقيقة",
    hours: "1 ساعة",

    LoginIn: "Log in",
    CreateAccount: "Sign up",
    Earn: "Earn",
    Crypto: "Crypto",

    Mintues: "Mintues",
    Home: "Home",
    Conversion: "Conversion",
    ASKOUR: "Browser community about crypto questions",
    PostAquestion: "Post a question",
    Inviting:
      "is Using cryptoscope,and has invited you to join,Create an account and join our communtiy",
    statistics: "statistics",
    invite: "invite",
    languages: "languages",
    news: "news",
    communitry: "communtiy",
    Converter: "Converter",
    MarketPlace: "MarketPalce",
    profile: "profile",
    WatchThe100Coins: "top 100 crypto coins ",
    RankingOfCoins: "Ranking of crypto coins",
    WatchTheLivePrive: "Watch the live price of",
    CandleChartCrypto: "Candlechart of ",
    LivePrices: "Live prices of ",
    Earnshiba: "Earn Free ",
    inviteYourFriend: "Invite your friend and earn Crypto",
    priceChanges24: "how much did the price of  change in the past 24 hours",
    Pricieover: "how much did the price of change over time",
  },
  pt: {
    You_must_be:
      "Você deve estar conectado ou ter uma conta para visualizar esta página. você pode criar uma conta gratuitamente ",
    My_Questions: "Minhas perguntas",
    My_Comments: "Meus comentários",
    SignOut: "Sair",
    Invite_code: "Código de convite",
    Country: "País",
    NicKName: "Apelido",
    Name: "Nome",
    CreatedAt: "Criado em",
    question_view: "Visualização de perguntas",
    Comment: "Comente",
    View: "Visão",
    Invite: "Convidar",

    Mintues: "1 Minuto",
    hours: "1 hora",
    market_cap: "Valor de mercado",
    total_supply: "Fornecimento total",
    circulating_supply: "Abastecimento circulante",
    max_supply: "Fornecimento máximo",
    total_volume: "Volume total",
    fully_diluted_valuation: "Avaliação totalmente diluída",
    All_Time_high: "Todo o tempo alto",
    All_Time_low: "Todo o tempo baixo",
    Social_medi_counts: "A mídia social conta:",
    Reddit_community: "Comunidade Reddit:",
    categories: "Categorias",
    official_page: "Página Oficial",
    Blockchain_sites: "Sites de blockchain",
    github: "Github",
    forums: "Fóruns",
    Scores: "Pontuações",
    developer: "desenvolvedor",
    community: "comunidade",
    liquidity: "liquidez",

    day: "1 Dia",
    daythree: "3 dias",
    week: "1 Semana",
    month: "1 mês",
    year: "1 ano",
    yearall: "Todos os tempos",

    current_price: "preços",
    Price_Chart: "Tabela de preços",
    live_price: "Preço ao vivo",
    Candle_Chart: "Gráfico de velas",

    LoginIn: "Log in",
    CreateAccount: "Sign up",
    Earn: "Earn",
    Crypto: "Crypto",

    Mintues: "Mintues",
    Home: "Home",
    Conversion: "Conversion",
    ASKOUR: "Browser community about crypto questions",
    PostAquestion: "Post a question",
    Inviting:
      "is Using cryptoscope,and has invited you to join,Create an account and join our communtiy",
    statistics: "statistics",
    invite: "invite",
    languages: "languages",
    news: "news",
    communitry: "communtiy",
    Converter: "Converter",
    MarketPlace: "MarketPalce",
    profile: "profile",
    WatchThe100Coins: "top 100 crypto coins ",
    RankingOfCoins: "Ranking of crypto coins",
    WatchTheLivePrive: "Watch the live price of",
    CandleChartCrypto: "Candlechart of ",
    LivePrices: "Live prices of ",
    Earnshiba: "Earn Free ",
    inviteYourFriend: "Invite your friend and earn Crypto",
    priceChanges24: "how much did the price of  change in the past 24 hours",
    Pricieover: "how much did the price of change over time",
  },
  es: {
    You_must_be:
      "Debe iniciar sesión o tener una cuenta para ver esta página. puedes crear una cuenta gratis",
    My_Questions: "Mis preguntas",
    My_Comments: "Mis comentarios",
    SignOut: "Desconectar",
    Invite_code: "Codigo de invitacion",
    Country: "País",
    NicKName: "Apodo",
    Name: "Nombre",
    CreatedAt: "Creado en",
    question_view: "Vista de preguntas",
    Comment: "Comentario",
    View: "Vista",
    Invite: "Invitar",

    Mintues: "1 Minuto",
    hours: "1 hora",
    market_cap: "Tapa del mercado",
    total_supply: "Suministro total",
    circulating_supply: "Suministro circulante",
    max_supply: "Suministro máximo",
    total_volume: "Volumen total",
    fully_diluted_valuation: "Valoración totalmente diluida",
    All_Time_high: "Su punto más alto",
    All_Time_low: "Todo el tiempo bajo",
    Social_medi_counts: "Las redes sociales cuentan:",
    Reddit_community: "Comunidad Reddit:",
    categories: "Categorías",
    official_page: "Página Oficial",
    Blockchain_sites: "Sitios de cadena de bloques",
    github: "Github",
    forums: "Foros",
    Scores: "Puntuaciones",
    developer: "desarrollador",
    community: "comunidad",
    liquidity: "liquidez",
    day: "1 Día",
    daythree: "3 días",
    week: "1 Semana",
    month: "1 Mes",
    year: "1 año",
    yearall: "Todo el tiempo",

    current_price: "precios",
    Price_Chart: "Gráfico de precios",
    live_price: "Precio en vivo",
    Candle_Chart: "Gráfico de velas",

    LoginIn: "Log in",
    CreateAccount: "Sign up",
    Earn: "Earn",
    Crypto: "Crypto",

    Mintues: "Mintues",
    Home: "Home",
    Conversion: "Conversion",
    ASKOUR: "Browser community about crypto questions",
    PostAquestion: "Post a question",
    Inviting:
      "is Using cryptoscope,and has invited you to join,Create an account and join our communtiy",
    statistics: "statistics",
    invite: "invite",
    languages: "languages",
    news: "news",
    communitry: "communtiy",
    Converter: "Converter",
    MarketPlace: "MarketPalce",
    profile: "profile",
    WatchThe100Coins: "top 100 crypto coins ",
    RankingOfCoins: "Ranking of crypto coins",
    WatchTheLivePrive: "Watch the live price of",
    CandleChartCrypto: "Candlechart of ",
    LivePrices: "Live prices of ",
    Earnshiba: "Earn Free ",
    inviteYourFriend: "Invite your friend and earn Crypto",
    priceChanges24: "how much did the price of  change in the past 24 hours",
    Pricieover: "how much did the price of change over time",
  },
  tr: {
    You_must_be:
      "Bu sayfayı görüntülemek için oturum açmış olmanız veya bir hesabınızın olması gerekir. ücretsiz bir hesap oluşturabilirsiniz",

    Mintues: "1 Dakika",
    hours: "1 saat",
    market_cap: "Piyasa değeri",
    total_supply: "Toplam arz",
    circulating_supply: "Dolaşımdaki arz",
    max_supply: "Maksimum arz",
    total_volume: "Toplam ses",
    fully_diluted_valuation: "Tamamen seyreltilmiş değerleme",
    All_Time_high: "Tüm Zamanların Yüksek",
    All_Time_low: "Tüm Zamanlar Düşük",
    Social_medi_counts: "Sosyal medya sayıları:",
    Reddit_community: "Reddit topluluğu:",
    categories: "Kategoriler",
    official_page: "Resmi sayfa",
    Blockchain_sites: "Blockchain siteleri",
    github: "Github",
    forums: "Forumlar",
    Scores: "Skorlar",
    developer: "geliştirici",
    community: "toplum",
    liquidity: "likidite",

    My_Questions: "Sorularım",
    My_Comments: "Yorumlarım",
    SignOut: "Oturumu Kapat",
    Invite_code: "Davet kodu",
    Country: "Ülke",
    NicKName: "Takma ad",
    Name: "İsim",
    CreatedAt: "Oluşturma tarihi",
    question_view: "Soru görünümü",
    Comment: "Yorum",
    View: "Görüş",
    Invite: "Davet etmek",

    day: "1 Gün",
    daythree: "3 Gün",
    week: "1 Hafta",
    month: "1 Ay",
    year: "1 Yıl",
    yearall: "Tüm zamanlar",
    current_price: "Fiyat",
    Price_Chart: "Fiyat Tablosu",
    live_price: "Canlı Fiyat",
    Candle_Chart: "mum grafiği",
    LoginIn: "Log in",
    CreateAccount: "Sign up",
    Earn: "Earn",
    Crypto: "Crypto",

    Mintues: "Mintues",
    Home: "Home",
    Conversion: "Conversion",
    ASKOUR: "Browser community about crypto questions",
    PostAquestion: "Post a question",
    Inviting:
      "is Using cryptoscope,and has invited you to join,Create an account and join our communtiy",
    statistics: "statistics",
    invite: "invite",
    languages: "languages",
    news: "news",
    communitry: "communtiy",
    Converter: "Converter",
    MarketPlace: "MarketPalce",
    profile: "profile",
    WatchThe100Coins: "top 100 crypto coins ",
    RankingOfCoins: "Ranking of crypto coins",
    WatchTheLivePrive: "Watch the live price of",
    CandleChartCrypto: "Candlechart of ",
    LivePrices: "Live prices of ",
    Earnshiba: "Earn Free ",
    inviteYourFriend: "Invite your friend and earn Crypto",
    priceChanges24: "how much did the price of  change in the past 24 hours",
    Pricieover: "how much did the price of change over time",
  },
};

function RenderContent({ ArrayCoin }) {
  if (ArrayCoin.length == 0) {
    return <h1>Loading</h1>;
  } else {
    return (
      <div>
        {ArrayCoin.map((data, key) => {
          return (
            <Prices
              TextLangugage={TextLangugage["en"]}
              ArrayCoin={data}
              SomeDataArray={data}
              Coin={data}
            />
          );
        })}
      </div>
    );
  }
  return null;
}
 function Homes(props) {
  async function CallItem() {
    let url =
      "https://api.coingecko.com/api/v3/coins/markets?vs_currency=USD&order=market_cap_desc&per_page=200&page=1&sparkline=false";
    let response = await fetch(url);

    if (response.status === 200) {
      let data = await response.json();
      SetArray(data);
    }
  }

  const [ArrayCoin, SetArray] = React.useState([]);
  useEffect(() => {
    if (props.WhichItem == undefined || props.WhichItem == null) {
      CallItem();
    }
  }, []);

  return (
    <div>
      {
        <RenderContent
          ArrayCoin={ArrayCoin}
        />
      }
    </div>
  );
}
const Home = React.memo(Homes);

export default Home;