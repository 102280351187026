import React from "react";

import { Line } from "react-chartjs-2";

let fontFamilysBold = "Poppins";
let fontFamilysLight = "Poppins";
let fontFamilysRegular = "Poppins";

let GoldColor = "#F0A202";

export default class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      Price: { one: 0, two: 0 },
      Prices: { one: 0, two: 0 },
      Height: [],
      res: [],
      coins: [],
      PricesArrayAll: [],
      ListOfPrice: [],
      pricesof: [],
      bnbPrice: 0,
    };
  }

  async GetPriceOfSHIB() {
    let Datas = await fetch(
      `https://api.binance.us/api/v3/ticker/price?symbol=BNBUSDT`
    )
      .then((res) => res.json())
      .then((res) => {
        this.setState({ bnbPrice: res.price });
      });
  }

  async fetchInformatino() {
    ////new Date().getTime();  // TEMP:
    if (this.props.SomeDataArray.Pricing == "fixed") {
      let PricofIt = await this.GetPriceOfSHIB();
      fetch(this.props.SomeDataArray.url)
        .then((res) => res.json())
        .then(async (res) => {
          let Prices = this.state.Price;
          Prices["one"] = res.guaranteedPrice.slice(0, 9);
          Prices["two"] = res.guaranteedPrice.slice(0, -8);
          //price
          this.setState({
            Price: Prices,
          });
        })
        .catch((error) => {
          let Prices = this.state.Price;
          Prices["one"] = Prices["two"];
          Prices["two"] = 0;
          //price
          this.setState({
            Price: Prices,
          });
        });
      return;
    }
    let timeNow = new Date().getTime();
    let startTime = timeNow - 86400 * 1000;
    var priceArrays = [];
    fetch(
      `https://api.binance.us/api/v3/klines?symbol=${this.props.SomeDataArray.Ticker.toUpperCase()}&interval=${"1h"}&startTime=${startTime}&endTime=${timeNow}`
    )
      .then((res) => res.json())
      .then((res) => {
        let array = [];
        var HigherPrice = 0;
        let nubmers = this.toPlainString(Number(res[1][1]));
        let pricesof = [nubmers];
        let PricesArrayAll = [];

        let Res = [];
        res.map((key, Index) => {
          let date = new Date(key[0]).toLocaleTimeString();
          Res.push({ y: key[1], x: date });
          PricesArrayAll.push({ y: parseFloat(key[1]), x: date });
          if (key[1] > HigherPrice) {
            HigherPrice = key[1];
          } else {
            priceArrays.push(key[1]);
          }
        });
        let newarrats = [];
        priceArrays.map((data, key) => {
          newarrats.push(HigherPrice / data - 1);
        });

        this.setState({
          Height: newarrats,
          Res: Res,
          PricesArrayAll: PricesArrayAll,
          pricesof: pricesof,
        });
      })
      .catch((error) => {});
  }

  async CallItem() {
    let url = "https://api.coingecko.com/api/v3/coins/terra-luna";
    let response = await fetch(url);

    if (response.status === 200) {
      let res = await response.json();
      let market_data = res.market_data;

      let coins = {
        ath: market_data.ath.usd,
        ath_change_percentage: market_data.ath_change_percentage.usd,
        ath_date: market_data.ath_date.usd,
        atl: 0.00180434,
        atl_change_percentage: 3424.14232,
        atl_date: "2017-11-12T00:00:00.000Z",
        circulating_supply: market_data.circulating_supply,
        current_price: market_data.current_price.usd,
        fully_diluted_valuation: null,
        high_24h: market_data.high_24h.usd,
        id: "tron",
        image:
          "https://assets.coingecko.com/coins/images/1094/large/tron-logo.png?1547035066",
        last_updated: "2022-06-30T11:48:59.946Z",
        low_24h: market_data.low_24h.usd,
        market_cap: market_data.market_cap.usd,
        market_cap_change_24h: -185303587.3136835,
        market_cap_change_percentage_24h:
          market_data.price_change_percentage_24h.usd,
        market_cap_rank: 13,
        max_supply: null,
        name: "TRON",
        price_change_24h: -0.001943474161087491,
        price_change_percentage_24h: market_data.price_change_percentage_24h,
        roi: {
          times: 32.4261384370696,
          currency: "usd",
          percentage: 3242.6138437069603,
        },
        symbol: "trx",
        total_supply: market_data.total_volume.usd,
        total_volume: market_data.total_supply,
      };
      let IteMarray = [];
      IteMarray.push(coins);
      this.setState({
        coins: IteMarray,
      });
    }
  }
  async componentDidMount() {
    let coins = this.props.Coin;
    this.setState({
      coins: coins,
    });
  }

  toPlainString(num) {
    //bnbPrice
    return ("" + +num).replace(
      /(-?)(\d*)\.?(\d*)e([+-]\d+)/,
      function (a, b, c, d, e) {
        return e < 0
          ? b + "0." + Array(1 - e - c.length).join(0) + c + d
          : b + c + d + Array(e - d.length + 1).join(0);
      }
    );
  }
  FormateString(tring) {
    let Items =
      tring != null || tring != undefined
        ? String(tring).includes(".")
          ? tring
          : tring.toLocaleString()
        : "-";
    return Items;
  }
  returnInformation() {
    var Item = {};
    let TextLangugage = this.props.TextLangugage;

    Item = this.props.Coin;
    if (Item.high_24h != undefined) {
    } else {
      return null;
    }

    let Low = Item.low_24h;
    let current_price = Item.current_price - Low;
    let Hight = Item.high_24h - Low;
    var Percentage = 100 - ((Hight - current_price) / Hight) * 100;
    if (Percentage > 100) {
      Percentage = "100%";
    } else if (Percentage < 0) {
      Percentage = "0%";
    } else {
      Percentage = String(Percentage) + "%";
    }
    return (
      <div
        onClick={() => {}}
        style={{
          display: "flex",
          position: "relative",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          minHeight: 50,
          borderRadius: 5,
          marginLeft: "1%",
        }}
      >
        <div className="ColumnIdd" style={{ width: 210 }}>
          <div className="TitleIdd">{TextLangugage.current_price}</div>

          <div className="TitleIddUnder">
            <div
              className="TitleIddUnder"
              style={{
                flexDirection: "row",
                display: "flex",
                color:
                  Item.price_change_percentage_24h > 0
                    ? "green"
                    : "rgb(255 145 145)",
              }}
            >
              {String(Item.current_price).includes(".")
                ? Item.current_price
                : Item.current_price.toLocaleString()}
              $
              <div
                className="MarketChane"
                style={{
                  color:
                    Item.price_change_percentage_24h > 0
                      ? "green"
                      : "rgb(255 145 145)",
                  margin: 3,
                  fontSize: 9,
                }}
              >
                ( <span className="Percentage">%</span>
                {Item.price_change_percentage_24h.toFixed(0)} /24h)
              </div>
            </div>

            <div style={{ width: 200, marginTop: 10 }}>
              <div
                style={{
                  width: "100%",
                  borderRadius: 9,
                  height: 5,
                  backgroundColor: "lightgray",
                }}
              >
                <div
                  style={{
                    width: Percentage,
                    position: "relative",
                    borderRadius: 9,
                    background:
                      "linear-gradient(90deg, rgba(255,174,90,1) 0%, rgba(187,125,6,1) 100%)",
                    height: "100%",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "rgba(255,255,255,0.2)",
                      position: "absolute",
                      top: -40,
                    }}
                  ></div>
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div className="HighAndLow">
                  {this.FormateString(Item.low_24h)}$
                </div>
                <div className="HighAndLow">
                  {this.FormateString(Item.high_24h)}$
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ width: 20, height: 10 }} />
        <div
          width={{ width: 210, marginLeft: 30, marginRight: 30, height: 50 }}
        >
          <Line
            datasetIdKey="id"
            width={120}
            height={50}
            options={{
              responsive: true,
              scales: { y: { display: false }, x: { display: false } },
              plugins: {
                legend: {
                  display: false,
                  position: "top",
                },
              },
            }}
            data={{
              datasets: [
                {
                  id: 1,
                  data: this.state.Res,
                  backgroundColor: "rgba(255,255,255,0)",
                  borderColor: "rgba(255,255,255,0.2)",
                  fill: false,
                  showLine: true,
                  pointStyle: "line",
                  pointRadius: 0,
                },
              ],
            }}
          />
        </div>
        <div style={{ width: 20, height: 10 }} />
        <div className="ColumnIdd">
          <div className="TitleIdd">{TextLangugage.market_cap}</div>
          <div className="TitleIddUnder">
            <span className="Dollar">$</span>
            {Item.market_cap.toLocaleString()}
          </div>

          <div
            className="MarketChane"
            style={{
              color:
                Item.market_cap_change_percentage_24h > 0
                  ? "green"
                  : "rgb(255 145 145)",
            }}
          >
            <span className="Percentage">%</span>
            {Item.market_cap_change_percentage_24h}
          </div>
        </div>
        <div className="ColumnIdd">
          <div className="TitleIdd">{TextLangugage.circulating_supply}</div>
          <div className="TitleIddUnder">
            <span className="Dollar"></span>
            {Item.circulating_supply.toLocaleString()}
          </div>
        </div>
        <div className="ColumnIdd">
          <div className="TitleIdd">{TextLangugage.total_supply}</div>
          <div className="TitleIddUnder">
            <span className="Dollar"></span>
            {Item.total_supply != null
              ? Item.total_supply.toLocaleString()
              : null}
          </div>
        </div>
        <div className="ColumnIdd">
          <div className="TitleIdd">{TextLangugage.total_volume}</div>
          <div className="TitleIddUnder">
            <span className="Dollar">$</span>
            {Item.total_volume != null
              ? Item.total_volume.toLocaleString()
              : null}
          </div>
        </div>

        <div className="ColumnIdd">
          <div className="TitleIdd">{TextLangugage.All_Time_high}</div>
          <div
            className="TitleIddUnder"
            style={{ flexDirection: "row", display: "flex" }}
          >
            {String(Item.ath).includes(".")
              ? Item.ath
              : Item.ath.toLocaleString()}
            <div
              className="MarketChane"
              style={{
                color:
                  Item.price_change_percentage_24h > 0
                    ? "green"
                    : "rgb(255 145 145)",
                margin: 3,
                fontSize: 9,
              }}
            >
              <span className="Percentage">%</span>
              {Item.ath_change_percentage}
            </div>
          </div>

          <div className="MarketChane" style={{ color: "gray" }}>
            {new Date(Item.ath_date).toLocaleDateString()}
          </div>
        </div>
      </div>
    );
  }
  render() {
    //let data =
    let changeses = this.state.pricesof.length > 0 ? this.state.pricesof[0] : 0;
    let calcualtepercantage =
      ((this.toPlainString(this.state.Price.one) - changeses) / changeses) *
      100;
    return (
      <div
        onClick={() => {}}
        style={{
          width: 1000,
          display: "flex",
          position: "relative",
          flexDirection: "row",
          backgroundColor: "rgb(255 255 255 / 4%)",
          justifyContent:
            this.props.WhichItem == undefined || this.props.WhichItem == null
              ? "flex-start"
              : "space-between",
          alignItems: "center",
          minHeight: 50,
          marginTop: 15,
          borderRadius: 5,
          marginLeft: "1%",
        }}
      >
        <div
          style={{
            width:
              this.props.WhichItem == undefined || this.props.WhichItem == null
                ? 120
                : "90vw",
            maxWidth:
              this.props.WhichItem == undefined || this.props.WhichItem == null
                ? "auto"
                : 500,
            minWidth:
              this.props.WhichItem == undefined || this.props.WhichItem == null
                ? "auto"
                : 300,
            display: "flex",
            position: "relative",
            flexDirection: "row",
            justifyContent:
              this.props.WhichItem == undefined || this.props.WhichItem == null
                ? "flex-start"
                : "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width:
                this.props.WhichItem == undefined ||
                this.props.WhichItem == null
                  ? "100%"
                  : "27%",
              display: "flex",
              paddingLeft: 5,
              alignItems: "center",
              justifyContent: "flex-start",
              flexDirection: "row",
            }}
          >
            <img
              src={this.props.Coin.image}
              style={{ width: 35, height: 35, resizeMode: "contain" }}
            />

            <div style={{ marginLeft: 10 }}>
              <div
                style={{
                  color: GoldColor,
                  fontFamily: fontFamilysBold,
                  fontSize: 13,
                  letterSpacing: 0.7,
                }}
              >
                {this.props.Coin.symbol.toUpperCase()}
              </div>
              <div
                style={{
                  fontFamily: fontFamilysLight,
                  fontWeight: "200",
                  fontSize: 12,
                  color: "white",
                }}
              >
                {this.props.Coin.name}
              </div>
              <div
                style={{
                  fontFamily: fontFamilysLight,
                  fontWeight: "200",
                  fontSize: 12,
                  color: "white",
                }}
              >
                {this.props.Coin.market_cap_rank}
              </div>
            </div>
          </div>

          {this.props.WhichItem == undefined ||
          this.props.WhichItem == null ? null : (
            <div width={{ width: 200, height: 50 }}>
              <Line
                datasetIdKey="id"
                width={120}
                height={50}
                options={{
                  responsive: true,
                  scales: { y: { display: false }, x: { display: false } },
                  plugins: {
                    legend: {
                      display: false,
                      position: "top",
                    },
                  },
                }}
                data={{
                  datasets: [
                    {
                      id: 1,
                      data: this.state.Res,
                      backgroundColor: "rgba(255,255,255,0)",
                      borderColor: "rgba(255,255,255,0.2)",
                      fill: false,
                      showLine: true,
                      pointStyle: "line",
                      pointRadius: 0,
                    },
                  ],
                }}
              />
            </div>
          )}

          {this.props.WhichItem == undefined ||
          this.props.WhichItem == null ? null : (
            <div
              style={{
                width: "33%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {this.toPlainString(this.state.Price.one) == "0" ? (
                <div>
                  <div
                    style={{
                      fontFamily: "Poppins-Light",
                      color: "white",
                      fontSize: 8,
                    }}
                  >
                    Loading..
                  </div>
                  <img style={{ width: 15, height: 15 }} src={"/Loading.gif"} />
                </div>
              ) : null}
              <div
                style={{
                  fontSize: 15,
                  fontFamily: fontFamilysBold,
                  fontWeight: "600",
                  color:
                    this.state.Price.one > this.state.Price.two ||
                    this.props.SomeDataArray.Pricing == "fixed"
                      ? "#76dc98"
                      : "#CF4A4A",
                }}
              >
                {this.props.SomeDataArray.Pricing
                  ? this.toPlainString(
                      this.state.Price.one * this.state.bnbPrice
                    )
                  : this.toPlainString(this.state.Price.one)}
                ${" "}
                {this.props.SomeDataArray.Pricing != "fixed"
                  ? this.state.Price.one > this.state.Price.two
                    ? "↑"
                    : "↓"
                  : ""}
              </div>
            </div>
          )}
        </div>
        {this.returnInformation()}
      </div>
    );
  }
}
